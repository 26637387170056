import React from "react"
import { Query } from "react-apollo"
import queryString from "query-string"

const withPreview = (args = { preview: false }) => Component => {
  const preview = props => {
    const parsed = queryString.parse(props.location.search)
    const { nonce, preview, id } = parsed
    // Id needs to be an int for preview query.
    // const id = parseInt(id, 10)

    /**
     * If no preview param, return the component with the preview props as false.
     */
    if (!preview) {
      return <Component preview={false} {...props} />
    }

    /**
     * Otherwise, run our Apollo query.
     */
    return (
      <Query
        returnPartialData={true} // why apollo? https://github.com/apollographql/react-apollo/issues/2114
        query={args.preview}
        variables={{
          id: String(id),
          nonce,
        }}
      >
        {response => {
          if (response.loading) return <p>Loading preview...</p>
          if (response.error) return <p>Error: ${response.error.message}</p>

          return <Component preview={response.data} {...props} />
        }}
      </Query>
    )
  }

  return preview
}

export default withPreview
